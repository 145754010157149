/* eslint-disable import/prefer-default-export */
import transport from './transport';
import * as methods from '../../common/constants';

/**
 * Гайд по именованию ресурсов
 * https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 *
 */

export const reportsGet = (data, ...options) => transport({
  url: '/v1/reports',
  method: methods.HTTP_GET,
  params: data,
  ...options,
});

export const reportsArchivesGet = (data, ...options) => transport({
  url: '/v1/reports/archives',
  method: methods.HTTP_GET,
  params: data,
  ...options,
});

export const reportsArchiveReportsGet = (id, data, ...options) => transport({
  url: `/v1/reports/archives/${id}`,
  method: methods.HTTP_GET,
  params: data,
  ...options,
});

export const reportsArchiveReportsReportRestartPut = (id, data, ...options) => transport({
  url: `/v1/reports/archives/report/${id}/restart`,
  method: methods.HTTP_PUT,
  params: data,
  ...options,
});
