/** ENGAGEMENT */
export const R_ENGAGEMENT_HOME = 'engagement.home';
export const R_ENGAGEMENT_DASHBOARD = 'engagement.dashboard';
export const R_ENGAGEMENT_SURVEYS = 'engagement.surveys';
export const R_ENGAGEMENT_SURVEYS_VIEW = 'engagement.surveys.view';
export const R_ENGAGEMENT_SURVEYS_CREATE = 'engagement.surveys.create';
export const R_ENGAGEMENT_REPORTS = 'engagement.reports';
export const R_ENGAGEMENT_REPORTS_ALL = 'engagement.reports.all';
export const R_ENGAGEMENT_REPORTS_ARCHIVES = 'engagement.reports.archives';
export const R_ENGAGEMENT_REPORTS_ARCHIVES_VIEW = 'engagement.reports.archives.view';
